import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from "vue-router";

import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter);

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { path: '/pagenotfound', component: PageNotFound},
    { path: '*', redirect: '/pagenotfound' }
  ],
  mode: 'history',
  history: 'true'
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
