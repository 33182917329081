<template>
  <div class="main_container">
    <div class="frame">
      <span class="img_wrapper">
        <img class="its_yves" :src=this.src>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",

  data: () => ({
    src: require("../assets/e404.jpg")
  }),
}
</script>

<style scoped>
.main_container {
  max-width: 1200px;
  margin: auto;
  background-color: transparent;
  position: relative;
}
.frame{
  text-align: center;
}

.img_wrapper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  padding-bottom: 20vh;
  margin-bottom: 10vh;
}

.its_yves {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 90vh;
  vertical-align: middle;
}
</style>
